<script lang="ts">
	import blockState from '$src/lib/stores/block-state'
	import DOMPurify from 'dompurify'
	import { createEventDispatcher } from 'svelte'
	// import BlockMenu from '../BlockMenu.svelte'

	const dispatch = createEventDispatcher()

	export let title: string
	export let description: string | null = ''
	export let size: 'small' | 'large' = 'large'
	export let iconUrl: string = '/visuals/icons/fork-knife.svg'
	export let bgColor: string = 'bg-red-200'
	export let imageUrl: string | null = null
	export let blockId: string | null = null
	export let isPublic: boolean = false

	function handleClick() {
		dispatch('click', { blockId })
	}

	function handleSetHoverState() {
		if (isPublic) return
		if (blockId != null) blockState.setHoveringPlaceId(blockId)
	}

	function handleUnsetHoverState() {
		if (isPublic) return
		if (blockId != null) blockState.cleartHoveringPlaceId()
	}
</script>

{#if size == 'large'}
	<div class="w-full flex items-center justify-between my-1 overflow-x-hidden">
		<button
			on:click={handleClick}
			on:mouseenter={handleSetHoverState}
			on:mouseleave={handleUnsetHoverState}
			disabled={!blockId}
			class="flex items-center text-brand-gray-5 truncate mr-4 group-hover:text-black dark:text-white group py-1 rounded-lg cursor-pointer"
		>
			{#if imageUrl != null}
				<span class="relative inline-block flex-shrink-0 pb-1">
					<img class="h-8 w-8 rounded-md object-cover" src={imageUrl} alt={title} />
					<img
						class={`absolute bottom-1 right-0 block translate-x-1/2 translate-y-1/2 transform rounded-full p-[2px] border-[3px] border-brand-gray-5 h-[20px] w-[20px] ${bgColor}`}
						src={iconUrl}
						alt={title}
					/>
				</span>
			{:else}
				<div class={`flex flex-shrink-0 items-center justify-center h-8 w-8 rounded-md ${bgColor}`}>
					<img class="h-5 w-5" src={iconUrl} alt={title} />
				</div>
			{/if}
			<div class="pl-3 text-start w-full">
				<div
					class={`text-xs font-medium dark:text-brand-gray-2 text-black truncate w-full pr-10 dark:group-hover:text-white transition-all ${
						isPublic ? 'blur-sm' : 'blur-0'
					}`}
				>
					{title}
				</div>
				<p
					class={`text-xs dark:text-brand-gray-3 text-brand-gray-5 truncate w-full pr-10 dark:group-hover:text-brand-gray-2 transition-all ${
						isPublic ? 'blur-sm' : 'blur-0'
					}`}
				>
					{description ?? 'No description available'}
				</p>
			</div>
		</button>
		<!-- {#if showMenu}<BlockMenu {blockId} />{/if} -->
		<slot />
	</div>
{:else}
	<button on:click={handleClick} class="flex group items-center space-x-2">
		<slot />
		<div
			class="text-[10px] font-medium text-brand-gray-5 group-hover:text-black dark:group-hover:text-white dark:text-brand-gray-3 transition-colors"
		>
			{title}
		</div>
	</button>
{/if}
