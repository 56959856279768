<script lang="ts">
	import BusPin from '../icons/custom/BusPin.svelte'
	import CarGaragePin from '../icons/custom/CarGaragePin.svelte'
	import ChampagneBottleGlassPin from '../icons/custom/ChampagneBottleGlassPin.svelte'
	import CocktailCoconutPin from '../icons/custom/CocktailCoconutPin.svelte'
	import CoffeeColdPin from '../icons/custom/CoffeeColdPin.svelte'
	import FastFoodTacosPin from '../icons/custom/FastFoodTacosPin.svelte'
	import IceCreamConePin from '../icons/custom/IceCreamConePin.svelte'
	import ParalympicsSwimmingPin from '../icons/custom/ParalympicsSwimmingPin.svelte'
	import SpaStonePin from '../icons/custom/SpaStonePin.svelte'
	import SportHorseRidingPin from '../icons/custom/SportHorseRidingPin.svelte'
	import MenuIcon from '../icons/MenuIcon.svelte'
	import PinIcon from '../icons/PinIcon.svelte'

	const imageUrl = '/visuals/stock/tulum-map-long.webp'
</script>

<div class="flex flex-col w-full relative">
	<img class="bg-cover bg-center w-full absolute -top-9 left-0 right-0" src={imageUrl} alt="map" />

	<div
		class="absolute top-[70px] left-0 z-10 w-40 -rotate-12 h-32 opacity-80 hover:opacity-100 cursor-pointer transition-opacity"
		style="
		clip-path: polygon(10% 0%, 60% 0%, 90% 100%, 20% 100%);
      background-color: rgba(249, 115, 22, 0.45);
    "
	/>
	<FastFoodTacosPin
		class="absolute top-16 left-32 z-10 cursor-pointer size-10 m-auto text-yellow-500 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<ChampagneBottleGlassPin
		class="absolute top-28 left-10 z-10 cursor-pointer size-10 m-auto text-green-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<IceCreamConePin
		class="absolute top-32 right-2 z-10 cursor-pointer size-10 m-auto text-yellow-500 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<SportHorseRidingPin
		class="absolute top-16 left-60 z-10 cursor-pointer size-10 m-auto text-sky-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<ParalympicsSwimmingPin
		class="absolute top-24 left-56 z-10 cursor-pointer size-10 m-auto text-sky-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<ParalympicsSwimmingPin
		class="absolute top-5 left-8 z-10 cursor-pointer size-10 m-auto text-sky-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<ParalympicsSwimmingPin
		class="absolute top-40 left-2 z-10 cursor-pointer size-10 m-auto text-sky-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<SpaStonePin
		class="absolute top-28 right-12 z-10 cursor-pointer size-10 m-auto text-blue-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<BusPin
		class="absolute top-2 left-40 z-10 cursor-pointer size-10 m-auto text-red-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<CarGaragePin
		class="absolute top-32 left-14 z-10 cursor-pointer size-10 m-auto text-red-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>
	<FastFoodTacosPin
		class="absolute top-32 left-16 z-10 cursor-pointer size-10 m-auto text-yellow-500 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<IceCreamConePin
		class="absolute top-14 right-48 z-10 cursor-pointer size-10 m-auto text-yellow-500 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<CoffeeColdPin
		class="absolute top-6 right-52 z-10 cursor-pointer size-10 m-auto text-yellow-500 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<ChampagneBottleGlassPin
		class="absolute top-16 right-48 z-10 cursor-pointer size-10 m-auto text-green-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<CocktailCoconutPin
		class="absolute top-10 right-52 z-10 cursor-pointer size-10 m-auto text-green-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<CocktailCoconutPin
		class="absolute top-8 right-12 z-10 cursor-pointer size-10 m-auto text-green-600 hover:scale-125 hover:-translate-y-1 scale-100 transition-all text-sm font-medium"
	/>

	<div class="flex justify-end w-full px-3 z-10 h-16">
		<div
			class="flex items-center cursor-pointer justify-center my-auto p-2 bg-brand-gray-6 hover:bg-brand-gray-5 rounded-full text-base font-medium text-white whitespace-nowrap focus:outline-none focus:ring-transparent focus:ring-offset-0"
		>
			<MenuIcon class="size-6 text-white" />
		</div>
	</div>
	<slot />
</div>
