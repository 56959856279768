<script lang="ts">
	import { override } from '$lib/utils/classnames'

	let className = ''
	export { className as class }
</script>

<div class={override('relative aspect-[366/729] mx-auto max-w-[366px]', className)}>
	<div
		class="absolute left-[calc(22/366*100%)] top-[calc(16/729*100%)] grid h-[calc(698/729*100%)] w-[calc(322/366*100%)] transform grid-cols-1 overflow-hidden bg-black pt-[calc(23/318*100%)]"
	>
		<slot />
	</div>
	<img
		src="/visuals/stock/phone-frame-dark.svg"
		alt=""
		class="pointer-events-none absolute inset-0 h-full w-full"
	/>
</div>
